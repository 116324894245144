@font-face {
  font-family: 'Plantin';
  src: url('fonts/Plantin.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'SourceSansPro-ExtraLight';
  src: url('fonts/SourceSansPro-ExtraLight.ttf') format('truetype'); 
}

@font-face {
  font-family: 'IBMPlexSans-Regular';
  src: url('fonts/IBMPlexSans-Regular.ttf') format('truetype'); 
}


@font-face {
  font-family: 'Neuton';
  src: url('fonts/Neuton.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'HelveticaNeueLTStd-BlkCn';
  src: url('fonts/HelveticaNeueLTStd-BlkCn.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Favorit-LightMono';
  src: url('fonts/Favorit-LightMono.otf'); /* IE9 Compat Modes */
}


@font-face {
  font-family: 'GT-America-Extended-Black';
  src: url('fonts/GT-America-Extended-Black.otf'); /* IE9 Compat Modes */
}





body{
  background-color: #F5F5F5;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  overflow-x: hidden;
}

*{
  outline: none;
}

a{
  color: black;
  text-decoration: none;
}


p{
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.main_container,
.about_container_main_content,
.source_container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.about_container_main_content h2{
  font-family: Plantin;
  font-weight: 100;
  border-bottom: 1px dotted;
  padding-bottom: 2vw;
}

.about_container_main{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 4vw;
  border-bottom: 1px solid;
  width: 60vw;
  padding-bottom: 4vw;
}


/* #main_containter_contact{
  background-color: black;
} */


.about_container_main span{
  font-family: plantin;
  font-size: 1vw;
  margin-bottom: 3vw;
  display: none;
}

.about_container a{
  border-bottom: 1px solid;
}



.bot_input_question_section{
  width: 100%;
  padding-bottom: 1vw;
}

.bot_input_prediction_section{
  position: relative;
  top: 3vw;
  margin-left: 1vw;
  right: 0;
}

.bot_input_prediction_section p{
  font-family: Plantin;
  background-color: #F5F5F5;
  border: 1px solid black;
  padding: 12px 18px;
  width: 80%;
  border-radius: 14px 14px 14px 0px;
}

.loading_bubbles{
  background-color: white;
  background-color: #F5F5F5;
  border: 1px solid black;
  padding: 12px 18px;
  width: fit-content;
  border-radius: 14px 14px 14px 0px;
}

.dot{
  display:inline-block;
	width:4px;
	height:4px;
	border-radius:50%;
	margin-right:3px;
	background:#303131;
	animation: wave 1.3s linear infinite;
}

.dot:nth-child(2) {
	animation-delay: -1.1s;
}

.dot:nth-child(3) {
	animation-delay: -0.9s;
}



.animated_bubble{
  border-bottom-left-radius: 0;
  animation: fadeIn ease 1s;
}

.inverted_bubble{
  filter: invert(1);
}

textarea:focus, input:focus{
    outline: none;
}

textarea,input{
  -webkit-appearance: none;
  border-color: none;
}

.bot_input_question_label{
  margin-top: 1vw;
  margin-bottom: 1vw;
}

input::placeholder{
  color: black;
  font-family: Plantin;
  font-size: 1.1vw;
}

.bot_input_question_section input{
  border: none;
  background-color: #F5F5F5;
  width: 85%;
  padding: 0;
  font-family: plantin;
  padding-left: 1vw;
  border-right: 1px solid;
  height: 100%;
  position: absolute;
  bottom: 0;
  font-size: 1.2vw;
}

.close_the_bot{
  position: absolute;
  padding: 0.8vw;
  width: 100%;
  background-color: black;
  cursor: pointer;
  display: flex;
  align-items: baseline;
}

.close_the_bot span:first-child{
  border-right: 1px solid;
  width: 40%;
}

.close_the_bot span:last-child{
  width: 60%;
  padding-left: 0.8vw;
  font-style: italic;
}

.close_the_bot span{
  border-bottom: 0;
  color: white;
  font-family: Plantin;
  font-size: 1.1vw;
}

.bot_input_question_section button{
  width: 10%;
  text-transform: uppercase;
  font-family: Plantin;
  color: black;
  border-radius: 2px;
  border-top: none;
  height: 100%;
  right: 0;
  z-index: 100;
  position: absolute;
  border: none;
  background-color: #F5F5F5;
}


.first_fold_container{
  display: flex;
  align-items: end;
  height: 100vh;
  position: relative;
  z-index: 10;
  flex-direction: row-reverse;
  border-top: 1px solid;
  border-bottom: 1px solid;
  z-index: 10000;
}

.displayed_metadata{
  height: 100%;
  width: 35vw;
  overflow: scroll;
  background-color: #F5F5F5;
}


.first_fold_cta_tweet{
  height: 5vh;
  background-color: #F5F5F5;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  border-top: 1px dotted;
  width: 35vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.first_fold_cta_tweet div{
  display: none;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.first_fold_cta_tweet div span{
  display: none;
  font-family: Arial narrow;
  font-size: 1vw;
  padding: 0.9vw;
  border-radius: 10px;
}


.sources_container_main_container{
  cursor: pointer;
  display: inline-block;
}


.typed_tweets{
  width: 65vw;
  background-color: black;
  height: 100%;
  overflow: scroll;
  border-left: 1px dotted;
}


.typed_tweets::-webkit-scrollbar {
    display: none;
}


.typed_tweets div{
  margin-left: 2vw;
  margin-top: 2vw;
  margin-right: 2vw;
  margin-bottom: 2vw;
  border-bottom: 1px solid white;
  padding-bottom: 2vw;
}

.typed_tweets div:last-child{
  border-bottom: none;
  margin-bottom: 0;
}

.typed_tweets div span{
  font-family: Plantin;
  font-size: 2.5vw;
  color: #e8e8e8;
}

.displayed_metadata::-webkit-scrollbar {
    display: none;
}

.metadata_first_fold{
  margin-left: 2vw;
  margin-right: 2vw;
  padding-top: 1vw;
  padding-bottom: 1vw;
  border-bottom: 1px solid;
  border: 1px solid;
  margin-top: 9px;
  padding: 11px;
  border-radius: 14px 14px 14px 0px;
}



.metadata_first_fold span{
  font-family: Plantin;
  font-size: 1.1vw;
  text-indent: 0.3vw !important;
  overflow-wrap: break-word;
  display: block;
}


#footnotes *{
  font-family: Plantin;
  font-size: 1.3vw;
}


.metadata_source_component span{
  display: block;
  font-family: Plantin;
  font-size: 1.15vw;
  overflow-wrap: break-word;
  text-transform: uppercase;
  color: black;
}

.pdf_source_component{
  display: flex;
  align-items: baseline;
}


.pdf_source_component div{
  width: 50%;
}

.pdf_source_component span{
  font-family: plantin;
  font-size: 1.1vw;
  text-transform: initial;
  color: black;
  padding: 1vw;
  border-radius: 10px;
  background-color: black;
  border-radius: 14px 14px 14px 0px;
  color: #F5F5F5;
}

.tweet_maped{
  margin-bottom: 1vw;
  padding: 1.5vw;
  background-color: black;
  border-radius: 14px 14px 14px 0px;
}


.tweet_maped span:nth-child(odd){
  color: #F5F5F5;
  font-family: plantin;
  font-size: 1.15vw;
}

.bot_error_message{
  bottom: 6vw;
  margin-left: 1vw;
  left: 0;
  position: absolute;
}

.bot_error_message span{
  font-family: Plantin;
  background-color: black;
  color: #F5F5F5;
  padding: 12px 18px;
  width: 80%;
  border-radius: 14px 14px 14px 0px;
}

.bot_input_outer{
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: end;
  width: 100%;
  height: 4vw;
  border-top: 1px solid;
}

.source_container_body{
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: scroll;
  border-right: 1px solid;
}

.source_container_body section h1{
  font-family: plantin;
  padding-top: 3vw;
  font-weight: 100;
  font-size: 1.7vw;
  color: black;
  margin: 0;
  border-bottom: 1px solid;
  margin-bottom: 2vw;
}

.source_container_body section{
  background-color: #F5F5F5;
  padding: 4vw;
  max-width: 42vw;
}

.source_container_body section:first-child{
  border-bottom: 1px solid;
}


.loading_screen{
  margin: 3vw;
  margin-left: 7.5vw;
  line-height: 1.6;
  text-transform: uppercase;
  font-family: Plantin;
  font-size: 1.2vw;
}

.loading_screen span{
  border-bottom: 1px solid black;
}

.typed_span,
.Cursor--blinking{
  display: none;
}

.sticky_container{
  position: fixed;
  margin-left: 2vw;
  padding-bottom: 2vw;
  top: 2vw;
  z-index: 10;
  display: block;
  width: 20vw;
}

.sticky_container div .first{
  font-family: Plantin;
  margin-bottom: 1vw;
  font-size: 1.2vw;
  text-transform: uppercase;
  cursor: pointer;
  background-color: black;
  color: white;
  border: none;
}


.source_indicator{
  position: fixed;
  right: 2vw;
  top: 2vw;
  background-color: #F5F5F5;
  border-radius: 14px 14px 14px 0px;
  padding: 1vw;
  border: 1px solid;
}

.source_indicator span{
  text-transform: uppercase;
  font-family: plantin;
  font-weight: lighter;
}


.source_tweet_indicator{
  position: fixed;
  left: 2vw;
  top: 2vw;
  background-color: #F5F5F5;
  border-radius: 14px 14px 0px 14px;
  padding: 1vw;
  border: 1px solid;
}

.source_tweet_indicator span{
  font-weight: lighter;
  font-family: Plantin;
  font-size: 1.2vw;
}

.sticky_container div .last{
  border-bottom: none;
}


.sticky_container span{
  display: block;
  padding-bottom: 0.3vw;
  padding-top: 0.3vw;
  font-family: Plantin;
  font-size: 1.1vw;
  text-transform: uppercase;
  border-radius: 14px 14px 14px 0px;
  background-color: #F5F5F5;
  padding: 11px;
  color: black;
  border: 1px solid;
  margin-bottom: 9px;
  width: fit-content;
}



.chatbox_container{
  width: fit-content;
  border: 1px dotted;
  border-radius: 20px;
  margin: 2vw;
  padding: 1vw;
  padding-left: 3vw;
  padding-right: 3vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #F5F5F5;
  display: flex;
}

.typed_container{
  width: 80vw;
  margin-top: 10vw;
  margin-bottom: 7vw;
  height: 18vh;
  border-bottom: 1px dotted black;
}

.sources_inner{
  margin-left: 3vw;
}


.sources_cell_container{
  width: 44.5vw;
  display: flex;
  align-items: baseline;
  padding-top: 3vw;
  padding-bottom: 3vw;
  border-bottom: 1px dotted #F5F5F5;
  margin: 1vw;
}


.sources_cell_container:last-child{
  border-bottom: none;
}

.caption_first_fold_container{
  width: 100vw;
  display: flex;
  align-items: baseline;
  background-color: #F5F5F5;
  z-index: 10;
  position: relative;
  padding-top: 1vw;
  padding-bottom: 1vw;
}

.caption_first_fold_container div{
  width: 33.33vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.caption_first_fold_container div span{
  font-family: Arial narrow;
font-size: 1vw;
}


.img_first_fold_container{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-top: 1px dotted #F5F5F5;
  position: relative;
  z-index: 10;
}

.img_first_fold_container div{
  width: 33.33vw;
  height: 105vh;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px dotted #F5F5F5;
  flex-direction: column;
}

.img_first_fold_container div:first-child{
  border-left: 1px dotted #F5F5F5;
}

.img_full img{
  width: 89%;
  background-color: #e8e8e8;
}

ul{
  margin-bottom: 0;
}

.about_container_main_master{
  width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.img_first_fold_container div span{
  color: white;
  position: absolute;
  bottom: 3vw;
  margin: 0;
  font-family: plantin;
  font-size: 1vw;
  text-align: center;
  text-transform: uppercase;
}


.img_first_fold_container span{
  margin-top: 3vw;
  margin-bottom: 3vw;
}

sup {
  font-family: plantin;
  font-size: 1vw;
  font-weight: 600;
}


.black{
  background-color: black;
}

.reverse{
  flex-direction: row-reverse;
}

.sources_cell_container span{
  font-family: Plantin;
  font-size: 1.4vw;
  color: #F5F5F5;
}

.source_container{
  background-color: black;
  padding-top: 3vw;
  padding-bottom: 3vw;
  border-top: 1px dotted;
  border-bottom: 1px dotted;
  position: relative;
  z-index: 100;
}


.source_container .sources_container_main_container:last-child .sources_cell_container{
  border-bottom: none !important;
}



.typed_span{
  border-bottom: none;
}


.sources_cell_container_first{
  width: 10%;
}

.sources_cell_container_second{
  width: 80%;
  display: flex;
  align-items: baseline;
}

.sources_cell_container_second div{
  width: 50%;
  padding-right: 2vw;
}

.sources_cell_container_third{
  width: 10%;
}

.sources_cell_container_third div{
  text-align: end;
}


.firstMain div span{
  color: black;
  font-family: SaolText-Light;
  font-weight: 100;
  font-size: 4vw;
}

.info_text_container,
.who_container,
.contact_container,
.acknowledgments_container,
.news_container,
.about_container{
  padding-top: 6vw;
  padding-bottom: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.news_container{
  padding-bottom: 0;
}


.contact_container div div:last-child{
  border-bottom: none;
}

.about_container div div:last-child .about_container_main{
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.about_container div div:last-child .about_container_main h1{
  display: none;
}

.headlines{
    width: 85vw;
}

.headlines h1{
  font-family: Plantin;
  font-size: 2.7vw;
  text-transform: uppercase;
  font-weight: 100;
}

.info_text_container p{
  margin-bottom: 3vw;
}

.info_text_container p:first-child{
  margin-bottom: 6vw;
  font-family: Plantin;
  font-size: 3vw;
  width: 65vw;
  text-align: center;
}


.info_text_container p:last-child{
  font-family: Plantin;
  line-height: 1.4;
  font-size: 1.4vw;
  margin-bottom: 0;
  width: 44vw;
  text-align: left;
}


.about_container_main_content_inner{
  width: 54vw;
}

.source_container p{
  margin-bottom: 3vw;
  font-family: Plantin;
  line-height: 1.6;
  font-size: 2.6vw;
  margin-bottom: 3vw;
}

.source_container p span{
  border-bottom: 1px dotted black;
}

.background_images_container .group_1 img:first-child{
  left: 2vw;
  bottom: 12vw;
}

.background_images_container .group_1 img:nth-child(2){
    left: 12vw;
    top: 24vw;
}

.background_images_container .group_1  img:nth-child(3){
    left: 12vw;
    bottom: 0;
}


.background_images_container .group_2 img:first-child{
  left: 2vw;
  bottom: 2vw;
}

.background_images_container .group_2 img:nth-child(2){
  left: 2vw;
  top: 30vw;
}


.background_images_container .group_3 img:first-child{
  right: 2vw;
  bottom: 7vw;
}

.background_images_container .group_3 img:nth-child(2){
  right: 2vw;
  top: 30vw;
}

.background_images_container .group_4 img:first-child{
  right: 2vw;
  bottom: 39vw;
}

.background_images_container .group_4 img:nth-child(2){
  right: 2vw;
  top: 20vw;
}


.background_images_container .group_5 img:first-child{
  right: 4vw;
  top: 5vw;
}


.background_images_container .group_6 img:first-child{
  right: 4vw;
  top: 5vw;
}

.background_images_container .group_8 img:first-child{
  left: 4vw;
  bottom: 18vw;
  display: none;
}

.background_images_container .group_7 img:first-child{
  right: 4vw;
  top: 10vw;
}

.background_images_container .group_9 img:first-child{
  left: 4vw;
  bottom: 10vw;
}

.background_images_container .group_9 img:nth-child(2){
  right: 4vw;
  bottom: 10vw;
}


.background_images_container .group_10 img:first-child{
  left: 4vw;
  bottom: 10vw;
  display: none;
}


.background_images_container .group_11 img:first-child{
  right: 5vw;
  bottom: 10vw;
}


.background_images_container{
  z-index: 100;
}

.background_images_container img{
  position: fixed;
  /* filter: drop-shadow(2px 4px 6px black); */
}

.background_images_container .group_1 .small{
  max-width: 5vw;
}

.background_images_container .group_1 .big{
  max-width: 11vw;
}


.background_images_container .group_2 .big{
  max-width: 22vw;
}

.background_images_container .group_2 .small{
  max-width: 1vw;
}

.background_images_container .group_3 .big{
  max-width: 20vw;
}

.background_images_container .group_3 .small{
  max-width: 4vw;
}


.background_images_container .group_4 .big{
  max-width: 16vw;
}

.background_images_container .group_4 .small{
  max-width: 6vw;
}

.background_images_container .group_5 .big{
      max-width: 23vw;
}

.background_images_container .group_5 .small{
  max-width: 6vw;
}


.background_images_container .group_6 .big{
  max-width: 7vw;
}


.background_images_container .group_7 .big{
  max-width: 10vw;
}


.background_images_container .group_8 .big{
    max-width: 53vw;
}

.background_images_container .group_9 .small{
  max-width: 14vw;
}

.background_images_container .group_9 .big{
  max-width: 20vw;
}

.background_images_container .group_10 .small{
  max-width: 20vw;
}

.background_images_container .group_11 .small{
  max-width: 29vw;
}

.group_12 img{
  width: 10vw;
  right: 10vw;
  top: 10vh
}

.group_13 img{
  width: 20vw;
  left: 2vw;
  bottom: 10vh;
}

.group_14 img{
  width: 13vw;
  left: 2vw;
  bottom: 10vh;
}

.group_15 img{
  width: 20vw;
  left: 2vw;
  bottom: 10vh;
}



.email_form{
  flex-direction: column;
  display: flex;
  align-items: baseline;
  width: 45vw;
}

.email_form input, .email_form textarea{
  width: 43vw;
  color: none;
  border: 1px solid black;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none; /*remove the resize handle on the bottom right*/
  font-family: Plantin;
  font-size: 1.4vw;
  padding: 1vw;
  color: black;
}



.email_form input::placeholder,
.email_form textarea::placeholder{
  color: black;
  text-transform: uppercase;
  font-size: 1.2vw;
}

.email_form input{
  margin-bottom: 2vw;
}

.email_form a{
  margin-top: 2vw;
  background-color: white;
  border: 1px solid;
  padding: 1vw;
  font-family: Plantin;
}

.contact_container{
  margin-bottom: 6vw;
}

.d3_component{
  width: 95vw;
  background-color: transparent;
  height: 100vh;
  border: 1px dotted;
}

.svg_animation{
  border: 1px dotted black;
}

.visualisation_menu{
  display: flex;
  margin-bottom: 3vw;
  width: 80vw;
  margin-top: 4vw;
}

.visualisation_menu span{
  font-family: Plantin;
  text-transform: uppercase;
  font-size: 1vw;
  border: 1px dotted;
  margin-right: 2vw;
  padding: 1vw;
  padding-left: 1vw;
  padding-right: 1vw;
  border-radius: 20px;
}


.sources_body_container{
  margin-bottom: 3vw;
  border-bottom: 1px dotted;
}


.about_container h1,
.news_container h1{
  margin-top: 0;
  margin-bottom: 3vw;
  font-family: Plantin;
  font-size: 2.7vw;
  width: 65vw;
  text-align: center;
  font-weight: lighter;
}

.about_container p,
.news_container p{
  font-family: Plantin;
  line-height: 1.4;
  font-size: 1.4vw;
  margin-bottom: 0;
  width: 44vw;
}

.typed_code{
  position: absolute;
    top: 200vh;
}

.typed_code img{
  width: 10vw;
}


.bot_message_handler{
  display: none;
}

.who_container,
.contact_container,
.acknowledgments_container{
  display: flex;
  align-items: baseline;
  width: 44vw;
  padding-top: 0;
  padding-bottom: 0;
}


.large_image_section{
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5vw;
  background-color: black;
  padding-bottom: 5vw;
}

.large_image_section img{
  width: 75vw;
}

.who_container p,
.acknowledgments_container p{
  font-family: Plantin;
  line-height: 1.4;
  font-size: 1.3vw;
  margin-bottom: 3vw;
}

.who_container{
  margin-top: 6vw;
}

.who_container h1{
  border-bottom: 1px solid;
  padding-bottom: 1vw;
}

.who_container section{
  display: block;
}



.who_container section:last-child,
.acknowledgments_container section:last-child{
  margin-bottom: 0;
}

.bot_component_container{
  position: fixed;
  height: 33vw;
  width: 368px;
  bottom: 0;
  right: 0;
  box-shadow: -1px 0px 16px 2px black;
  margin: 1.8vw;
  border: 1px solid;
  background-color: #F5F5F5;
  border-radius: 20px;
  z-index: 10000;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 100000;
}

.sources_cell_background{
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  left: 0;
  top: 0;
}

.toggle_cell {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: #F5F5F5;
  border-radius: 14px 14px 14px 0px;
  border: 1px solid black;
  padding: 1.2vw;
  margin: 1vw;
}


.bot_component_container span a{
  color: #F5F5F5;
}



.bot_icon{
  cursor: pointer;
  position: fixed;
  font-family: sans-serif;
  font-size: 18px;
  line-height: 24px;
  width: 300px;
  background: black;
  border-radius: 40px;
  padding: 14px;
  text-align: center;
  bottom: 2vw;
  right: 2vw;
  padding-left: 0;
  padding-right: 0;
  /* animation: bot_animation 5s infinite; */
  border-radius: 14px 14px 0px 14px;
  border: 1px solid black;
}

.bot_icon span{
  font-family: Plantin;
  color: #F5F5F5;
  text-transform: uppercase;
}

/* .bot_icon_left:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid black;
  border-right: 12px solid transparent;
  border-top: 12px solid black;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
} */


.who_container section h1,
.acknowledgments_container section h1{
  font-family: Plantin;
  font-size: 2vw;
  font-weight: 100;
  margin: 0;
  margin-bottom: 2vw;
  text-align: left;
  border-bottom: 1px solid;
}


.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  text-align: left;
  animation:
    typing 3.5s steps(40, end)
}

/* The typing effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: orange; }
}

@keyframes bot_animation {
  0%, 11.1%, 100% {
    transform: none;
  }
  22.2% {
      /* transform: skewX(-12.5deg) skewY(-12.5deg); */
      transform: skew(-4deg, -4deg);
  }
  33.3% {
      /* transform: skewX(6.25deg) skewY(6.25deg); */
      transform: skew(2deg, 2deg);
  }
  44.4% {
      /* transform: skewX(-3.125deg) skewY(-3.125deg); */
      transform: skew(-1deg, -1deg);
  }
  55.5% {
      /* transform: skewX(1.5625deg) skewY(1.5625deg); */
      transform: skew(0.5deg, 0.5deg);
  }
  66.6% {
      /* transform: skewX(-.78125deg) skewY(-.78125deg); */
      transform: skew(-0.25deg, -0.25deg);
  }
  77.7% {
      /* transform: skewX(.390625deg) skewY(.390625deg); */
      transform: skew(0.125deg, 0.125deg);
  }
  88.8% {
      /* transform: skewX(-.1953125deg) skewY(-.1953125deg); */
      transform: skew(-0.0625deg, -0.0625deg);
  }
}


@keyframes fadeIn {
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
}

@keyframes wave {
	0%, 60%, 100% {
		transform: initial;
	}

	30% {
		transform: translateY(-5px);
	}
}
